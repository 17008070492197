import "./Navbar.css";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { LangDropDown } from "../customs/lang drop down/LangDropDown";
import { ProfileDp } from "../customs/profile drop down/ProfileDp";
import { MobProfileDp } from "../customs/profile mob dp/MobProfileDp";
import logoSrc from "../../assets/images/NoyyaBlack72.png";
import { BurgerMenuIcon } from "../../assets/svg/burgerMenuIcon";
import { CloseMenuIcon } from "../../assets/svg/closeMenuIcon";

export const Navbar = () => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const { i18n, t } = useTranslation();
  const { isAuth } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleOutsideClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <section id="navbar">
        <nav>
          <div className="left">
            <div className="logo">
              <NavLink to={`${i18n.language}`} aria-label="Home">
                <img src={logoSrc} alt="Noyya logo" />
              </NavLink>
            </div>
            <div className="navlinks">
              <NavLink
                className={"nav-link"}
                to={`${i18n.language}/cars`}
                aria-label={t("navbar.cars").toString()}
              >
                {t("navbar.cars")}
              </NavLink>
              {/* <NavLink
                className={"nav-link"}
                to={`${i18n.language}/rental-cars`}
                aria-label={t("navbar.rentalCars").toString()}
              >
                {t("navbar.rentalCars")}
              </NavLink> */}
              <NavLink
                className={"nav-link"}
                to={`${i18n.language}/showrooms`}
                aria-label={t("navbar.showrooms").toString()}
              >
                {t("navbar.showrooms")}
              </NavLink>
              <NavLink
                className={"nav-link"}
                to={`${i18n.language}/number-plates`}
                aria-label={t("navbar.numberPlates").toString()}
              >
                {t("navbar.numberPlates")}
              </NavLink>
              <NavLink
                className={"nav-link disabled"}
                to={`${i18n.language}/buyWithNoyya`}
                aria-label={t("navbar.buyWithNoyya").toString()}
              >
                {t("navbar.buyWithNoyya")}
                <span className="soon-badge">{t("navbar.soon")}</span>
              </NavLink>

              {!isAuth && (
                <NavLink
                  className={"nav-link"}
                  to={`${i18n.language}/sign-in`}
                  aria-label={t("navbar.signIn").toString()}
                >
                  {t("navbar.signIn")}
                </NavLink>
              )}
            </div>
          </div>

          <div className="right">
            <div className="language">
              <LangDropDown />
            </div>
            <button
              ref={menuRef}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
              className="menu"
              aria-label="Toggle navigation menu"
            >
              {isMenuOpen ? <CloseMenuIcon /> : <BurgerMenuIcon />}
            </button>
            {isAuth ? (
              <div className="profile">
                <ProfileDp />
              </div>
            ) : (
              <div className="register">
                <NavLink
                  className={"nav-link"}
                  to={`${i18n.language}/register`}
                  aria-label={t("navbar.register").toString()}
                >
                  {t("navbar.register")}
                </NavLink>
              </div>
            )}
          </div>
        </nav>
      </section>
      <div
        style={
          isMenuOpen
            ? {
                transform: "translateX(0%)",
                transition: "transform 0.5s ease-in-out",
              }
            : {}
        }
        className={`menu-links ${
          i18n.language === "en" ? "menu-links-ltr" : "menu-links-rtl"
        }`}
      >
        <div>
          {isAuth ? (
            <MobProfileDp />
          ) : (
            <header>
              <NavLink
                onClick={() => {
                  handleOutsideClick();
                }}
                className={"sign-in"}
                to={`${i18n.language}/sign-in`}
                aria-label={t("navbar.signIn").toString()}
              >
                {t("navbar.signIn")}
              </NavLink>
              <NavLink
                onClick={() => {
                  handleOutsideClick();
                }}
                className={"register"}
                to={`${i18n.language}/register`}
                aria-label={t("navbar.register").toString()}
              >
                {t("navbar.register")}
              </NavLink>
            </header>
          )}
          <div className="solid"></div>

          <NavLink
            onClick={() => {
              handleOutsideClick();
            }}
            className={"nav-link"}
            to={`${i18n.language}/cars`}
            aria-label={t("navbar.cars").toString()}
          >
            {t("navbar.cars")}
          </NavLink>
          {/* <NavLink
            onClick={() => {
              handleOutsideClick();
            }}
            className={"nav-link"}
            to={`${i18n.language}/rental-cars`}
            aria-label={t("navbar.rentalCars").toString()}
          >
            {t("navbar.rentalCars")}
          </NavLink> */}
          <NavLink
            onClick={() => {
              handleOutsideClick();
            }}
            className={"nav-link"}
            to={`${i18n.language}/showrooms`}
            aria-label={t("navbar.showrooms").toString()}
          >
            {t("navbar.showrooms")}
          </NavLink>
          <NavLink
            onClick={() => {
              handleOutsideClick();
            }}
            className={"nav-link"}
            to={`${i18n.language}/number-plates`}
            aria-label={t("navbar.numberPlates").toString()}
          >
            {t("navbar.numberPlates")}
          </NavLink>
          <NavLink
            onClick={() => {
              handleOutsideClick();
            }}
            className={"nav-link disabled"}
            to={`${i18n.language}/buyWithNoyya`}
            aria-label={t("navbar.buyWithNoyya").toString()}
          >
            {t("navbar.buyWithNoyya")}
            <span className="soon-badge">{t("navbar.soon")}</span>
          </NavLink>
          <div className="solid"></div>

          <div style={{ alignSelf: "flex-start" }}>
            <LangDropDown />
          </div>
        </div>
      </div>
      <div
        className="nav-overlay"
        onClick={handleOutsideClick}
        style={
          isMenuOpen
            ? {
                visibility: "initial",
                opacity: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }
            : {}
        }
      ></div>
    </>
  );
};
